import React, { useEffect } from 'react';
import styles from '../styles/Features.module.css';
import { isMobile } from 'react-device-detect';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation, EffectCreative } from 'swiper/modules';
import "swiper/css";
import "../styles/SimpleSlider.module.css";

interface FeatureProps {
  icon: string;
  title: string;
  description: string;
}

const Feature: React.FC<FeatureProps> = ({ icon, title, description }) => {
  return (
    <div className={`${styles.feature}`}>
      <div className={`${styles.icon}`}>
        <img src={icon} alt={title} width={134} height={134} />
      </div>
      <h3 className={`${styles.title}`}>{title}</h3>
      <p className={`${styles.description}`} dangerouslySetInnerHTML={{ __html: description }}></p>
    </div>
  );
};

const Features: React.FC = () => {

  return (
    <section>
      <div className={`${styles.container}`}>
        <h2 className={`${styles.header}`}>Meet genuine singles and find your match!</h2>
        {!isMobile ?
          <div className="flex flex-col md:flex-row justify-center">
            <Feature
              icon="verified.png"
              title="Verified Profiles"
              description="Our verification process ensures you are connecting with real people."
            />
            <Feature
              icon="questionnaire.png"
              title="Engaging Questionnaires"
              description="Our detailed questionnaires help you find matches that truly resonate with you."
            />
            <Feature
              icon="secure.png"
              title="Safe and Secure"
              description="Your privacy and security are our top priorities."
            />
          </div> :
          <Swiper loop={true} slidesPerView={1.2} centeredSlides={true} speed={1000} autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }} modules={[Autoplay, Pagination, Navigation]}
          
            spaceBetween={4}>
            <SwiperSlide>
              <Feature
                icon="verified.png"
                title="Verified Profiles"
                description="Our verification process ensures you are connecting with real people."
              />
            </SwiperSlide>
            <SwiperSlide>
              <Feature
                icon="questionnaire.png"
                title="Engaging Questionnaires"
                description="Our detailed questionnaires help you find matches that truly resonate with you."
              />
            </SwiperSlide>
            <SwiperSlide>
              <Feature
                icon="secure.png"
                title="Safe and Secure"
                description="Your privacy and security are <br/>our top priorities."
              />
            </SwiperSlide>
            <SwiperSlide>
              <Feature
                icon="verified.png"
                title="Verified Profiles"
                description="Our verification process ensures you are connecting with real people."
              />
            </SwiperSlide>
            <SwiperSlide>
              <Feature
                icon="questionnaire.png"
                title="Engaging Questionnaires"
                description="Our detailed questionnaires help you find matches that truly resonate with you."
              />
            </SwiperSlide>
            <SwiperSlide>
              <Feature
                icon="secure.png"
                title="Safe and Secure"
                description="Your privacy and security are <br/>our top priorities."
              />
            </SwiperSlide>
          </Swiper>
        }
      </div>
    </section>
  );
};

export default Features;
