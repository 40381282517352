import styles from './index.module.css';

const data = [
  {
    subtitle: `Introduction`,
    content: `Welcome to EyeKlass, a website operated by BST Creative INC. These Terms and Conditions
govern your use of EyeKlass and its services. By accessing or using our site, you agree to
comply with these terms. If you do not agree, please do not use our site.`
  },{
    subtitle: `Acceptance of Terms`,
    content: `By creating an account and using EyeKlass, you agree to abide by these Terms and
Conditions, as well as our Privacy Policy.`
  },{
    subtitle: `Changes to Terms`,
    content: `EyeKlass reserves the right to update these Terms and Conditions at any time. Any changes
will be posted on this page with an updated revision date. Your continued use of the site
signifies your acceptance of the revised terms.`
  },{
    subtitle: `Description of Services`,
    content: `EyeKlass provides a platform for individuals to connect and interact. Our services include
user profiles, messaging, events, and subscription-based features.`
  },{
    subtitle: `User Obligations and Conduct`,
    content: `Users must be 18 years or older to register and use EyeKlass. Verification of age and
identity is required. Users agree to provide accurate and truthful information and to update
their information as necessary.`
  },{
    subtitle: `Account Registration`,
    content: `To access certain features, users must create an account by providing a valid email address
and other required information. Users are responsible for maintaining the confidentiality of
their account details and are liable for all activities under their account.`
  },{
    subtitle: `Payment Terms`,
    content: `Certain features on EyeKlass are subscription-based. Users can choose from various
subscription plans. Payment terms will be detailed at the time of purchase.`
  },{
    subtitle: `Refund Policy`,
    content: `All purchases made on EyeKlass are non-refundable. Users are encouraged to thoroughly
review subscription details before purchasing.`
  },{
    subtitle: `Intellectual Property Rights`,
    content: `EyeKlass and its content, including logos, graphics, and text, are the property of BST
Creative INC. Users may not use our intellectual property without prior written consent.`
  },{
    subtitle: `User Content`,
    content: `Users retain ownership of the content they upload but grant EyeKlass a non-exclusive,
royalty-free license to use, display, and distribute their content within the platform.`
  },{
    subtitle: `Privacy Policy`,
    content: `EyeKlass is committed to protecting user privacy. Our Privacy Policy outlines how we
collect, use, and safeguard your personal information.`
  },{
    subtitle: `Limitation of Liability`,
    content: `EyeKlass is not liable for any direct, indirect, incidental, or consequential damages arising
from the use or inability to use our services. Users agree to use the site at their own risk.`
  },{
    subtitle: `Termination`,
    content: `EyeKlass reserves the right to terminate user accounts for violations of these Terms and
Conditions or any other policies. Termination may result in loss of access to services and
content.`
  },{
    subtitle: `Governing Law`,
    content: `These Terms and Conditions are governed by the laws of the jurisdiction where BST
Creative INC. is based, without regard to conflict of law principles.`
  },{
    subtitle: `Dispute Resolution`,
    content: `Any disputes arising from these Terms and Conditions will be resolved through binding
arbitration in accordance with the rules of the jurisdiction where BST Creative INC. is based.`
  },{
    subtitle: `Indemnification`,
    content: `Users agree to indemnify and hold EyeKlass and BST Creative INC. harmless from any
claims, damages, or expenses arising from their use of the site or violation of these terms.`
  },{
    subtitle: `Third-Party Links`,
    content: `EyeKlass may contain links to third-party websites. We are not responsible for the content
or practices of these external sites.`
  },{
    subtitle: `Warranty Disclaimer`,
    content: `EyeKlass is provided 'as is' without warranties of any kind. We do not guarantee the
accuracy, completeness, or reliability of our services.`
  },{
    subtitle: `Contact Information`,
    content: `For any questions or concerns, users can contact us at contact@blkspade.com.`
  },{
    subtitle: `Miscellaneous`,
    content: `These Terms and Conditions constitute the entire agreement between users and EyeKlass
regarding the use of our services. Any failure to enforce a right or provision does not
constitute a waiver of that right.`
  },{
    subtitle: `Amendments`,
    content: `EyeKlass reserves the right to amend these Terms and Conditions at any time. Users will be
notified of significant changes through the site or email.`
  },{
    subtitle: `Entire Agreement`,
    content: `These Terms and Conditions, along with our Privacy Policy, represent the complete
agreement between users and EyeKlass.`
  },
];

const TermsAndConditions: React.FC = () => {
  return (
    <>
      <div className={`${styles.container}`}>
        <h2 className={`${styles.title}`}>Terms & Conditions</h2>
        {
          data.map((item, index) => (
            <div key={index}>
              <h3 className={`${styles.subtitle}`}>{item.subtitle}</h3>
              <p className={`${styles.content}`}>{item.content}</p><br />
            </div>
          ))
        }
      </div>
    </>
  );
};

export default TermsAndConditions;
