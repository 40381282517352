import styles from '../styles/HeroSection.module.css';

const HeroSection: React.FC = () => {
  return (
    <section className={`${styles.backgroundSection}`}>
      <div className={`${styles.container}`}>
        <h1 className={`${styles.title}`}>Where Genuine Connections Begin</h1>
        <p className={`${styles.coming}`}>Coming Soon in 2025!</p>
        <p className={`${styles.join}`}>
          Join our community and be the first to experience a new way of dating.
        </p>
        <p className={`${styles.description}`}>
          Our dating platform focuses on genuine connections through verified profiles and engaging questionnaires.
          Get ready for a unique and safe dating experience.
        </p>
      </div>
    </section>
  );
};

export default HeroSection;
