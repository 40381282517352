import styles from './index.module.css';

const data = [
  {
    subtitle: `Introduction`,
    content: `Welcome to EyeKlass. This Privacy Policy explains how we collect, use, disclose, and protect ` + 
            `your personal information when you use our website and services. By using EyeKlass, you ` +
            `agree to the collection and use of information in accordance with this policy.`
  }, {
    subtitle: `Information Collection`,
    content: `EyeKlass collects personal information necessary for a dating website, including but not limited to: <br />
- Name <br />
- Email address <br />
- Age <br />
- Location <br />
- Profile information <br />
- Payment information <br />
- ID verification details`,
  }, {
    subtitle: `Use of Information`,
    content: `The information we collect is used primarily to: <br />
- Provide and improve our services <br />
- Verify user identity <br />
- Enhance user experience <br />
- Process payments <br />
- Communicate with users <br />
- Conduct research and analysis`,
  }, {
    subtitle: `Sharing of Information`,
    content: `EyeKlass does not sell or rent your personal information to third parties. We may share
your information with third parties under the following circumstances: <br />
- With your consent <br />
- For processing payments and other transactions <br />
- To comply with legal obligations <br />
- To protect the rights and safety of EyeKlass, our users, and the public`,
  }, {
    subtitle: `Cookies and Tracking Technologies`,
    content: `EyeKlass uses cookies and similar tracking technologies to enhance user experience and
analyze website performance. You can control the use of cookies through your browser
settings.`,
  }, {
    subtitle: `User Rights`,
    content: `Users have the following rights regarding their personal information: <br />
- Access: You can request a copy of the personal information we hold about you. <br />
- Correction: You can request that we correct any inaccurate or incomplete information. <br />
- Deletion: You can request that we delete your personal information under certain circumstances. <br />
- Objection: You can object to the processing of your personal information under certain circumstances. <br />
 <br />
To exercise these rights, please contact us at contact@blkspade.com.`,
  }, {
    subtitle: `Data Security`,
    content: `EyeKlass takes the security of your personal information seriously. We implement standard
security measures and continuously improve our security practices to protect your data
from unauthorized access, alteration, disclosure, or destruction.`,
  }, {
    subtitle: `Changes to this Privacy Policy`,
    content: `EyeKlass may update this Privacy Policy from time to time. Any changes will be posted on
this page with an updated revision date. Your continued use of the site signifies your
acceptance of the revised policy.`,
  }, {
    subtitle: `Contact Information`,
    content: `If you have any questions or concerns about this Privacy Policy, please contact us at
contact@blkspade.com.`,
  },
];
const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <div className={`${styles.container}`}>
        <h2 className={`${styles.title}`}>Privacy Policy</h2>
        {
          data.map((item, index) => (
            <div key={index}>
              <h3 className={`${styles.subtitle}`}>{item.subtitle}</h3>
              <p className={`${styles.content}`} dangerouslySetInnerHTML={{ __html: item.content }}></p>
              <br/>
            </div>
          ))
        }
      </div>
    </>
  );
};

export default PrivacyPolicy;
