import styles from '../styles/Footer.module.css';
import { isMobile } from 'react-device-detect';

const Footer: React.FC = () => {

  return (
    <footer className={`${styles.footer}`}>
      {!isMobile ?
        <div className={`${styles.footer__container}`}>
          <div className={`${styles.footer__top}`}>
            <div className={`${styles.footer__links}`}>
              <a href="/privacy-policy" target="_blank">Privacy Policy</a>
              <span>/</span>
              <a href="/terms-and-conditions" target="_blank">Terms & Conditions</a>
              <span>/</span>
              <a href="/contact-form" target="_blank">Contact Us</a>
            </div>
            <div className={`${styles.footer__social}`}>
              <a href="https://www.facebook.com/profile.php?id=61563069105630&mibextid=ZbWKwL" aria-label="Facebook" target="_blank" rel="noopener noreferrer">
                <img src="facebook.svg" alt="facebook" width={24} height={24} />
              </a>
              <a href="https://x.com/EyeklassD?t=GbxmGuVr89HU3CUKDL7PAg&s=09" aria-label="Twitter" target="_blank" rel="noopener noreferrer">
                <img src="twitter.svg" alt="twitter" width={24} height={24} />
              </a>
              <a href="https://www.instagram.com/eyeklass_dating?igsh=MWpkbDE0ZnM1NzEzaQ==" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
                <img src="instagram.svg" alt="instagram" width={24} height={24} />
              </a>
            </div>
          </div>
          <div className={`${styles.footer__copyright}`}>
            Copyright © 2024 – Present. EyeKlass Powered By BST Creative
          </div>
        </div> :
        <div className={`${styles.footer__container}`}>
          <div className={`${styles.footer__top}`}>
            <div className={`${styles.footer__social}`}>
              <a href="https://facebook.com" aria-label="Facebook" target="_blank" rel="noopener noreferrer">
                <img src="facebook.svg" alt="facebook" width={24} height={24} />
              </a>
              <a href="https://twitter.com" aria-label="Twitter" target="_blank" rel="noopener noreferrer">
                <img src="twitter.svg" alt="twitter" width={24} height={24} />
              </a>
              <a href="https://instagram.com" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
                <img src="instagram.svg" alt="instagram" width={24} height={24} />
              </a>
            </div>

            <div className={`${styles.footer__links}`}>
              <a href="/privacy-policy">Privacy Policy</a>
              <span>/</span>
              <a href="/terms-and-conditions">Terms & Conditions</a>
              <span>/</span>
              <a href="/contact-form">Contact Us</a>
            </div>
          </div>
          <div className={`${styles.footer__copyright}`}>
            Copyright © 2024 – Present. EyeKlass Powered By BST Creative
          </div>
        </div>
      }
    </footer>
  );
};

export default Footer;
