import React, { useState } from 'react';
import './index.css';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

interface FormData {
  name: string;
  email: string;
  message: string;
}

const CONTACT_US_MUTATION = gql`
  mutation ContactUs($name: String!, $email: String!, $message: String!) {
    contactUs(name: $name, email: $email, message: $message)
  }
`;

const ContactForm: React.FC = () => {
  const [contactUs] = useMutation(CONTACT_US_MUTATION);
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Form Data:', formData);

    const { name, email, message } = formData;
    try {
      const { data } = await contactUs({ variables: { name, email, message } });
      if (data.contactUs) {
        alert('Message sent successfully!');
      } else {
        alert('Failed to send message.');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      alert('An error occurred.');
    }
  };

  return (
    <form className="contact-form" onSubmit={handleSubmit} >
      <div className="form-group" >
        <label htmlFor="name" > Name: </label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      < div className="form-group" >
        <label htmlFor="email" > Email: </label>
        < input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      < div className="form-group" >
        <label htmlFor="message" > Message: </label>
        < textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
        />
      </div>
      <button type="submit" className="submit-button"> Submit </button>
    </form>
  );
};

export default ContactForm;