import React, { useState } from 'react';
import styles from '../styles/SubscriptionForm.module.css';
import client from '../lib/apolloClient';
import { gql, useMutation } from '@apollo/client';

const CREATE_USER = gql`
  mutation CreateUser($firstName: String!, $lastName: String!, $email: String!) {
    createUser(firstName: $firstName, lastName: $lastName, email: $email) {
      id
      firstName
      lastName
      email
    }
  }
`;

const SubscriptionForm: React.FC = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const [createUser, { data, loading, error }] = useMutation(CREATE_USER);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    createUser({
      variables: {
        firstName: firstName,
        lastName: lastName,
        email: email,
      },
    });

    // Optionally reset the form
    setFirstName('');
    setLastName('');
    setEmail('');
  };

  return (
    <section className={`${styles.backgroundSection}`}>
      <div className={`${styles.container}`}>
        <h2 className={`${styles.title}`}>Stay Updated and Get Exclusive Early Access!</h2>
        <form className={`${styles.form}`} onSubmit={onSubmit}>
          <div className={`${styles.row}`}>
            <input
              type="fname"
              placeholder="First Name"
              className={`${styles.input}`}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />

            <input
              type="lname"
              placeholder="Last Name"
              className={`${styles.input}`}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className={`${styles.row}`}>
            <input
              type="email"
              placeholder="Email Address"
              className={`${styles.input}`}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="submit" className={`${styles.button}`}>
              Notify Me
            </button>
          </div>
        </form>
        <p className={`${styles.description}`}>
          Sign up now and get a FREE one-month subscription to our Premium Services at launch.
        </p>
      </div>
    </section>
  );
};

export default SubscriptionForm;
