import Features from "../../components/Features";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import HeroSection from "../../components/HeroSection";
import SubscriptionForm from "../../components/SubscriptionForm";


const Home: React.FC = () => {

  return (
    <div className="bg-white">
      <Header />
      <HeroSection />
      <Features />
      <SubscriptionForm />
      <Footer />
    </div>
  )
}

export default Home;